import React from "react";
import { graphql } from "gatsby";
import Loadable from "@loadable/component";
import withPreview from "../../utility/with-preview";
const FormCambioProdotto = Loadable(() => import("../form/form-cambio-prodotto"));
const FormManutenzione = Loadable(() => import("../form/form-manutenzione"));
const FormAttivazioneConsulente = Loadable(() => import("../form/form-attivazione-consulente"));
const FormGuastoCaldaia = Loadable(() => import("../form/form-guasto-caldaia"));
const FormPreventivo = Loadable(() => import("../form/form-preventivo"));
const FormCoupon = Loadable(() => import("../form/form-coupon"));
const FormConsulenza = Loadable(() => import("../form/form-consulenza"));
const FormCallMeBack = Loadable(() => import("../form/form-call-me-back"));
const FormCallYou = Loadable(() => import("../form/form-call-you"));
const FormAssistenzaTecnica = Loadable(() => import("../form/form-assistenza-tecnica"));

const FormContatti = ({ data, ...other }) => {
  const { tipologiaForm } = data;
  const formType = tipologiaForm?.value?.[0];
  switch (formType) {
    case "form16":
      return <FormCambioProdotto data={data} {...other} />;
    case "form17":
      return <FormManutenzione data={data} {...other} />;
    case "form20":
      return <FormAttivazioneConsulente data={data} {...other} />;
    case "form21":
      return <FormGuastoCaldaia data={data} {...other} />;
    case "form23":
      return <FormPreventivo data={data} {...other} />;
    case "form25":
      return <FormCoupon data={data} {...other} />;
    case "form30":
      return <FormConsulenza data={data} {...other} />;
    case "form33":
      return <FormCallMeBack data={data} {...other} />;
    case "form35":
      return <FormCallYou data={data} {...other} />;
    case "form37":
      return <FormAssistenzaTecnica data={data} {...other} />;
    default:
      return null;
  }
};

export default withPreview(FormContatti);
export const fragment = graphql`
  fragment FormContattiFragment on LiferayJskFormContatti {
    liferayFields {
      siteId
      articleId
    }
    tipologiaForm {
      value
    }
    vendibilita {
      value
    }
    provUrlType {
      value
    }
    prodottoInteressato {
      value
    }
    oblique {
      value
    }
    titoloDin {
      value
    }
    sottoTitoloDin {
      value
    }
    image {
      node {
        gatsbyImageData
      }
    }
    sottotitoloDopoVerificaComune {
      value
    }
    testoFlagPrivacy {
      value
      content {
        privacyDin {
          value
        }
        pdfInformativaPrivacy {
          node {
            publicURL
          }
        }
      }
    }
    thankYouMessage {
      value
    }
  }
`;
